import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "store/StoreConfigs";
import { actions } from "models/userManagement/actions";
import { getPermissionKey } from "helpers/permissionHelpers";
import { MarvelPermissionStatus } from "models/userManagement/userManagementModels";
import clsx from "clsx";
import { Helmet } from "react-helmet";
import { GeneralErrorComponentWrapper } from "components/ErrorComponents/GeneralErrorPage";
import { reportingStyles } from "./ReportingStyles";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { LynxIcon } from "icons/LynxIcon";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { infoText } from "lynxConstants/reportsText";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import { ReportType } from "api/models/reporting/reportingApi";
import dayjs from "dayjs";

export const Reporting = observer(() => {
    const { identityStore, permissionsStore, reportingStore } = useStore();
    const classes = reportingStyles();

    const [stickyEnabled, setStickyEnabled] = useState(false);
    const withSticky = (className: string) => clsx(className, stickyEnabled && "sticky");
    const navigate = useNavigate();
    const currentTimezone = dayjs.tz.guess();

    const permissionKey = getPermissionKey(actions.customer.tor.reporting.view, identityStore.currentCustomer.id);
    const permissionStatus = permissionsStore.getPermissionStatus(permissionKey);

    useEffect(() => {
        if (identityStore.isSystemSpace || permissionStatus === MarvelPermissionStatus.Deny) {
            navigate(identityStore.startPageAvailable);
        }
    }, [identityStore.startPageAvailable]);

    const tableHeaders = ["Report Name", "Description"];
    const reportVariants = [
        { name: "Event Root Cause", description: "Report Description", type: ReportType.EventRootCause },
    ];
    const title = `Reports (${reportVariants.length})`;

    return (
        <GeneralErrorComponentWrapper>
            <main className={classes.root}>
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <Grid container direction="row">
                    <LynxTypography variant="h1" className={classes.title}>
                        {title}
                    </LynxTypography>
                    <Grid container item className={classes.infoBadge}>
                        <LynxIcon name="infoS" />
                        <LynxTypography variant="body-s">{infoText}</LynxTypography>
                    </Grid>
                </Grid>
                <Table>
                    <TableHead>
                        <TableRow className={classes.headerRow}>
                            {tableHeaders.map((header) => (
                                <TableCell className={clsx(classes.cellBorderBottom, classes.headerCell)} key={header}>
                                    <LynxTypography color="neutral400" variant="h3">
                                        {header}
                                    </LynxTypography>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportVariants.map((report) => (
                            <React.Fragment key={report.name}>
                                <TableRow className={classes.tableRow}>
                                    <TableCell className={classes.cellBorderBottom}>
                                        <LynxTypography variant="body-l">{report.name}</LynxTypography>
                                    </TableCell>
                                    <TableCell className={classes.cellBorderBottom}>
                                        <LynxTypography variant="body">{report.description}</LynxTypography>
                                    </TableCell>
                                    <TableCell className={classes.cellBorderBottom}>
                                        <LynxButton
                                            leftIcon={<LynxIcon name="download" />}
                                            className={classes.actionButton}
                                            variant="tertiary"
                                            size="medium"
                                            onClick={() => {
                                                reportingStore.getReportCsv({
                                                    customerId: identityStore.currentCustomer.id,
                                                    type: report.type,
                                                    currentTimezone,
                                                });
                                            }}
                                            loading={reportingStore.progressFlags.exportingReport}
                                        >
                                            Download
                                        </LynxButton>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </main>
        </GeneralErrorComponentWrapper>
    );
});
