import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const reportingStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.listPagePadding,
        },

        title: {
            paddingRight: "1.5rem",
        },

        infoBadge: {
            alignSelf: "center",
            alignContent: "center",
            backgroundColor: theme.colors.primary.blue75,
            padding: "0 1rem",
            borderRadius: "0.25rem",
            width: "max-content",
            height: "2rem",
            gap: "0.25rem",
        },

        cellBorderBottom: {
            borderBottom: "none",
        },

        headerCell: {
            paddingBottom: "1rem",
        },

        headerRow: {
            verticalAlign: "bottom",
        },

        tableRow: {
            boxShadow: theme.mainShadow,
            backgroundColor: "white",
            verticalAlign: "center",
        },

        actionButton: {
            width: "auto",
            float: "right",
            marginRight: "0.5rem",
        },
    })
);
