import { Immutable } from "helpers/typeHelpers";

// TODO: refactor user management routing
const _routes = {
    root: "/",
    login: "/login",
    termsAndConditions: "/termsAndConditions",
    forgotPassword: "/forgot-password",
    recoveryEmailed: "/recovery-emailed",
    passwordSuccessfullyReset: "/password-successfully-reset",
    resetPassword: "/reset-password",
    recoveryTokenInvalid: "/recovery-token-invalid",
    events: "/events",
    eventDetails: { path: "/events/:eventId", to: (eventId: string) => `/events/${eventId}` },
    eventsCreate: "/events/create",
    eventsEdit: { path: "/events/:eventId/edit", to: (eventId: string) => `/events/${eventId}/edit` },
    eventsAudit: { path: "/events/:eventId/audit", to: (eventId: string) => `/events/${eventId}/audit` },
    stabilityForms: "/stabilityForms",
    stabilityFormDetails: {
        path: "/stabilityForms/:stabilityFormId",
        to: (stabilityFormId: string) => `/stabilityForms/${stabilityFormId}`,
    },
    stabilityFormsCreate: "/stabilityForms/create",
    stabilityFormsEdit: {
        path: "/stabilityForms/:stabilityFormId/edit",
        to: (stabilityFormId: string) => `/stabilityForms/${stabilityFormId}/edit`,
    },
    stabilityFormsAudit: {
        path: `/stabilityForms/:stabilityFormId/audit`,
        to: (stabilityFormId: string) => `/stabilityForms/${stabilityFormId}/audit`,
    },
    reporting: "/reporting",
    userManagement: "/userManagement",
    pageNotFound: "/pageNotFound",
};

const routes: Immutable<typeof _routes> = _routes;
export default routes;
